<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="活动名称" style="width: 200px;"></el-input>
			</div>
			<!-- 活动状态 -->
			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="status" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addPackagePrice'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleAdd">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading='loading'>
				<el-table-column prop="Name" label="活动名称"></el-table-column>
				<el-table-column label="活动时间">
					<template slot-scope="scope">
						{{scope.row.StartTime}}
						<span>至</span>
						{{scope.row.EndTime}}
					</template>
				</el-table-column>
				<el-table-column prop="IsOpenValue" label="状态"></el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scop">
						<div v-if="scop.row.IsOpenValue=='已结束'">
							<div class="table-button" @click="handleTableEdit(scop.row)">查看</div>

							<button-permissions :datas="'copyActivitiesBtn'">
								<div class="table-button" @click="handleTableCopy(scop.row)">复制</div>
							</button-permissions>

							<button-permissions :datas="'deleteActivitiesBtn'">
								<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scop.row.Id)">删除</div>
							</button-permissions>
							
						</div>

						<div v-else>

							<button-permissions :datas="'editActivitiesBtn'">
								<div class="table-button" @click="handleTableEdit(scop.row)">编辑</div>
							</button-permissions>

              <button-permissions :datas="'cancelActivitiesBtn'">
                <div class="table-button" @click="handleTableFail(scop.row.Id)">使终止</div>
              </button-permissions>

              <button-permissions :datas="'copyActivitiesBtn'">
                <div class="table-button" @click="handleTableCopy(scop.row)">复制</div>
              </button-permissions>
						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		groupShareIndex,
		groupShareDelete,
		groupShareClose,
	} from '@/api/goods'

	import buttonPermissions from '@/components/buttonPermissions';

	export default {
		components: {
			buttonPermissions,
		},
		data() {
			return {
				loading: false,
				searchKey: '',
				status: null,
				tableData: [],
				statusOptions: [{
						value: 0,
						label: '未开始'
					},
					{
						value: 1,
						label: '进行中'
					},
					{
						value: 2,
						label: '已结束'
					}
				],
				page: {
					total: 0,
					current: 1,
					size: 20
				},
			}
		},
		created() {
			this.getList()
		},
		methods: {
			async getList() {
				this.loading = true;
				try {
					let data = {
						KeyWords: this.searchKey, // 搜索关键字
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
						State: this.status
					}
					let result = await groupShareIndex(data);

					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			handleFilter() {
				this.page.current = 1;
				this.getList()
			},
			handleAdd() {
				this.$router.push({
					path: '/market/groupShare/editShareActivities'
				})
			},
			handleTableEdit(val,type) {
				this.$router.push({
					path: '/market/groupShare/editShareActivities',
					query: {
						id: val.Id
					}
				})
			},
			handleTableCopy(record) {
				this.$router.push({
					path: '/market/groupShare/editShareActivities',
					query: {
						id: record.Id,
						copy: 1
					}
				})
			},
			// table使终止
			handleTableFail(id) {
				this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使终止？', '提示', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					try {
						const res = await groupShareClose({
							Id : id
						})
						if (res.IsSuccess) {
							this.$message({showClose: true,
								message: '终止成功',
								type: 'success'
							})
							this.getList();
						}
					} catch(e) {
						return 
					} finally {

					}

				}).catch(() => {
          this.$message({
            showClose: true,
            message: '已取消终止',
            type: 'warning'
          })
				})
			},
			// table删除
			handleTableDelete(id) {
				this.$confirm('是否确认删除活动？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
          const res = await groupShareDelete({
            Id : id
          })
          if (res.IsSuccess) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            })
            this.getList();
          }
				}).catch(() => {
          this.$message({
            showClose: true,
            message: '已取消删除',
            type: 'warning'
          })
        }).finally(() => {

        })
      },
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},

		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.table-button {
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog {
		width: 100%;

		::v-deep .el-dialog__header {
			border-bottom: 1px solid #ddd;
		}

		.content {
			padding: 0 30px;
			display: flex;
			flex-direction: row;

			.right {
				margin-left: 20px;
			}

			.text {
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}

		.button {
			margin: 0 auto;
			width: 200px;
		}
	}

	.data-dialog {
		.value {
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}

		.item {
			color: #666;
			margin-top: 10px;
		}
	}
</style>
